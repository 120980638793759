import React from "react"
import Layout from "../containers/Layout";
//import Image from "../components/genericComponents/Image";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import SEO from "../components/genericComponents/SEO";
import { StaticImage } from "gatsby-plugin-image";

const benefitsItems = [
  {
    description: "about_benefits_item1",
  },
  {
    description: "about_benefits_item2",
  },
  {
    description: "about_benefits_item3",
  },
  {
    description: "about_benefits_item4",
  },
  {
    description: "about_benefits_item5",
  },
  {
    description: "about_benefits_item6",
  },
]

const AboutPage = props => {
  const { t } = useTranslation()

  return (
    <Layout
      navBarProps={{
        navClass: "is--white",
      }}
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
      <SEO
        title={t('seo_about_title')}
        desc={t('seo_about_description')}
        pathname={"/sobre-nosotros/"}
        article={false}
        section={t('seo_about_section')}
        tags={{
          tag_1:t('seo_about_tag1'),
          tag_2:t('seo_about_tag2'),
          tag_3:t('seo_about_tag3'),
          tag_4:t('seo_about_tag4'),
        }}
      />
      <section className="header-about container-generic" data-scroll-section>
        <h1>{t("about_title")}</h1>
        <div className="header-about__content">
          <div className="header-about__content-column">
            <div className="header-about__content-column-title">
              {t("about_subtitle")}
            </div>
            <p>{t("about_header_pharagraph1")}</p>
          </div>
          <div className="header-about__content-column">
            <p>{t("about_header_pharagraph2")}</p>
          </div>
        </div>
      </section>
      <section className="about-claim container-generic" data-scroll-section>
        <div className="about-claim__content">
          <div className="about-claim__content-image">
            <StaticImage src="../assets/images/about-claim.png" alt={t("about_claim_title")} 
             placeholder="blurred"
             width={500}
             height={555}/>
            {/*<Image src="about-claim.png" alt={t("about_claim_title")} />*/}
          </div>
          <div className="about-claim__content-text">
            <h2>{t("about_claim_title")}</h2>
            <p>{t("about_claim_description")}</p>
          </div>
        </div>
      </section>
      <section className="about-benefits-container">
        <div className="about-benefits container-generic" data-scroll-section>
          <div className="about-benefits__content">
            {benefitsItems.map((item, index) => {
              return (
                <div key={index} className="about-benefits__content-item">
                  <div className="about-benefits__content-item-icon" />
                  <p
                    className="about-benefits__content-item-description"
                    dangerouslySetInnerHTML={{
                      __html: t(item.description),
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section className="about-behind container-generic" data-scroll-section>
        <div className="about-behind__content">
          <div className="about-behind__content-text">
            <div className="about-behind__content-text-title">
              {t("about_behind_title")}
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: t("about_behind_description"),
              }}
            />
          </div>
          <div className="about-behind__content-image">
            <StaticImage src="../assets/images/about-behind.png" alt={t("about_claim_title")} placeholder="blurred"/>
            
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
